import 'bootstrap'

window.schnelleStelle = {}

window.schnelleStelle.slideGallery = function (items, minPerSlide) {
  items.forEach((el) => {
    let next = el.nextElementSibling
    for (var i=1; i<minPerSlide; i++) {
      if (!next) {
        // wrap carousel by using first child
        next = items[0]
      }
      let cloneChild = next.cloneNode(true)
      el.appendChild(cloneChild.children[0])
      next = next.nextElementSibling
    }
  })
}

window.schnelleStelle.filterContent = function (e) {
  let term = e.target.value.toLowerCase()
  if (e.key === 'Enter') {
    e.target.blur()
  }
  if (e.key === 'Escape') {
    term = ''
    e.target.value = ''
  }
  const parentNodes = []
  document.querySelectorAll('[data-filter-content]').forEach(el => {
    if (term === '' || el.dataset.filterContent.includes(term)) {
      el.classList.remove('d-none')
    } else {
      el.classList.add('d-none')
    }

    if (parentNodes.includes(el.parentNode) === false) {
      parentNodes.push(el.parentNode)
    }
  })
  parentNodes.forEach(parentNode => {
    let filterResults = parentNode.children.length
    for (let i = 0; i < parentNode.children.length; i ++) {
      if (parentNode.children[i].classList.contains('d-none')) {
        filterResults--
      }
    }
    parentNode.dataset.filterResults = filterResults
    if (parentNode.previousElementSibling) {
      parentNode.previousElementSibling.dataset.followingFilterResults = filterResults
    }
  })
}

window.schnelleStelle.filterTagged = function (e) {
  document.querySelectorAll('[data-labels]').forEach(el => {
    if (el.dataset.labels.split(',').includes(e.target.dataset.showLabel)) {
      el.classList.remove('d-none')
    } else {
      el.classList.add('d-none')
    }
  })
}

window.schnelleStelle.subnavigation = {
  open: event => {
    if (window.outerWidth < 992) {
      event.target.nextElementSibling.classList.toggle('active')
    }
  },
  close: event => { event.target.parentElement.parentElement.classList.remove('active') }
}

window.onload = () => {
  document.querySelectorAll('[data-toggle-height]').forEach(trigger => {
    const el = trigger.previousElementSibling
    trigger.onclick = () => { el.classList.toggle('expanded'); return false }

    items = el.children
    hidden_items = items.reduce((result, item, idx) => {
      item_height = item.getBoundingClientRect().height
      if (result.pixels <= item_height / 2) {
        return result
      }
      return { products: result.products - 1, pixels: result.pixels - item_height }
    }, { products: items.length, pixels: list.getBoundingClientRect().height }).products

    trigger.title = `+ ${hidden_items} weitere`
  })
}

window.schnelleStelle.toggleVideo = (video, playButton) => {
  // Event listener for the play/pause button
  if (video.paused == true) {
    // Play the video
    video.play();

    // Update the button text to 'Pause'
    playButton.classList.add('pause')
    playButton.classList.remove('play')
  } else {
    // Pause the video
    video.pause();

    // Update the button text to 'Play'
    playButton.classList.add('play')
    playButton.classList.remove('pause')
  }
}

function jobInput(event) {
  const value = event.target.value
  if (value.length <= 2) return

  fetch(`${coreUrl}/isco/occupations?search=${value}*`)
    .then(response => response.json())
    .then(json => {
        const jobList = document.getElementById('job_list')
        jobList.innerHTML = ''

        json.forEach(value => {
          const option = document.createElement('option')
          option.value = value.label_de
          jobList.appendChild(option)
        })
    })
}

function placeInput(event) {
  const value = event.target.value
  if (value.length <= 2) return

  fetch(`${location.origin}/de/package_assistant?place=${value}`, { headers: { Accept: 'text/html' }})
    .then(response => response.json())
    .then(json => {
      const placeList = document.getElementById('place_list')
      placeList.innerHTML = ''

      json.forEach(value => {
        const option = document.createElement('option')
        option.value = value
        placeList.appendChild(option)
      })
    })
}
